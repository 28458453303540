import "core-js/modules/es6.function.name";
import React from "react";
import styled from "styled-components";
import { color, borderBottom, borderColor } from "styled-system";
import Div from "../../../packages/Div/src";
import PropTypeName from "./PropTypeName";
var TableRow = styled.div.withConfig({
  displayName: "PropsTable__TableRow",
  componentId: "z7wz1a-0"
})(["display:grid;grid-template-columns:repeat(3,33.333333%);", " ", " ", " code{font-family:menlo,monospace;font-size:0.875rem;background:#f6f5f8;padding:0.25rem 0.5rem;border-radius:0.25rem;display:inline-block;margin-bottom:0.25rem;}"], color, borderBottom, borderColor);

var PropsTable = function PropsTable(_ref) {
  var allComponentMetadata = _ref.allComponentMetadata,
      componentName = _ref.componentName;
  return React.createElement("div", {
    style: {
      padding: "2.5rem 0"
    }
  }, allComponentMetadata && allComponentMetadata.filter(function (_ref2) {
    var node = _ref2.node;
    return componentName ? node.displayName === componentName : true;
  }).map(function (_ref3) {
    var node = _ref3.node;
    return React.createElement("div", {
      key: node.displayName
    }, React.createElement("h2", null, node.displayName + " props"), React.createElement(Div, {
      borderRadius: "md",
      overflow: "hidden"
    }, React.createElement(TableRow, {
      style: {
        fontWeight: 700
      },
      bg: "grey.500",
      color: "grey.50"
    }, React.createElement(Div, {
      p: "1rem"
    }, "Name"), React.createElement(Div, {
      p: "1rem"
    }, "Type"), React.createElement(Div, {
      p: "1rem"
    }, "Default")), node.props && node.props.map(function (prop, i) {
      return React.createElement(TableRow, {
        key: "prop-" + i,
        borderBottom: "0.0625rem solid",
        borderColor: "grey.100"
      }, prop.name && React.createElement(Div, {
        p: "1rem"
      }, React.createElement("code", null, prop.name, React.createElement("span", {
        style: {
          color: "#f72f1c"
        }
      }, prop.required ? " *" : ""))), React.createElement(Div, {
        p: "1rem"
      }, React.createElement(PropTypeName, {
        type: prop.type
      })), React.createElement(Div, {
        p: "1rem"
      }, prop.defaultValue && React.createElement("code", null, prop.defaultValue.value)));
    })));
  }));
};

export default PropsTable;