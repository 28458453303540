import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.function.name";
import React from "react";
var fieldNames = {
  enum: "value",
  union: "name"
};

var PropTypeName = function PropTypeName(_ref) {
  var type = _ref.type;
  if (!type || !type.name) return "";

  if (Object.keys(fieldNames).includes(type.name)) {
    return React.createElement(React.Fragment, null, type.value.map(function (i) {
      return i[fieldNames[type.name]];
    }).map(function (item) {
      return React.createElement("code", {
        key: item,
        style: {
          marginRight: "0.25rem"
        }
      }, item);
    }));
  }

  return React.createElement("code", null, type.name);
};

export default PropTypeName;