import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint-disable import/max-dependencies */

/* eslint-disable import/no-namespace */
import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { ThemeProvider } from "styled-components";
import Seo from "../Seo";
import Sidebar from "../Sidebar";
import Content from "../Content";
import SiteHeader from "../SiteHeader";
import SiteFooter from "../SiteFooter";
import PropsTable from "../PropsTable";
import GlobalStyle from "../GlobalStyle";
import * as theme from "../../../packages/theme/src";
import Container from "../../../packages/Container/src";
import Div from "../../../packages/Div/src";
import Flex from "../../../packages/Flex/src";
import { Card } from "../../../packages/Card/src";
import { Grid, GridItem } from "../../../packages/Grid/src";
import { Heading } from "../../../packages/Typography/src";
import Link from "../../../packages/Link/src";
import iconSpriteSheet from "../../images/datastory-icons.svg";
import flagSpriteSheet from "../../images/datastory-flags.svg";

var customTheme = _objectSpread({}, theme, {}, theme.typography, {
  spriteSheet: {
    icon: iconSpriteSheet,
    flag: flagSpriteSheet
  }
});

var PackageTemplate = function PackageTemplate(_ref) {
  var data = _ref.data,
      pageContext = _ref.pageContext;
  var mdx = data.mdx,
      allComponentMetadata = data.allComponentMetadata;
  var edges = allComponentMetadata ? allComponentMetadata.edges : [];
  return React.createElement(ThemeProvider, {
    theme: customTheme
  }, React.createElement(React.Fragment, null, React.createElement(Seo, null), React.createElement(GlobalStyle, null), React.createElement(SiteHeader, null), React.createElement(Div, {
    bg: "grey.50"
  }, React.createElement(Container, {
    pb: "2.5rem"
  }, React.createElement(Grid, null, React.createElement(GridItem, {
    gridColumn: "span 3",
    py: "2.5rem"
  }, React.createElement(Sidebar, {
    sidebarType: "components",
    activeItem: pageContext.name
  })), React.createElement(GridItem, {
    gridColumn: "span 9",
    py: "2.5rem"
  }, React.createElement(Card, {
    raised: true,
    py: "5rem",
    px: "5rem",
    borderRadius: "lg",
    bg: "white"
  }, React.createElement(Flex, null, React.createElement(Div, {
    width: "78%"
  }, React.createElement(Content, {
    toc: mdx.tableOfContents
  }, React.createElement(MDXRenderer, null, mdx.body), edges.length > 0 && React.createElement(PropsTable, {
    allComponentMetadata: edges
  }))), React.createElement(Div, {
    width: "22%",
    pl: "1.5rem"
  }, mdx.tableOfContents.items[0].items && React.createElement(Div, {
    position: "sticky",
    top: "0",
    py: "1.25rem"
  }, React.createElement(Heading, {
    as: "h2",
    size: "sm",
    mt: "0",
    mb: "1.25rem"
  }, "Sections"), mdx.tableOfContents.items[0].items.map(function (item) {
    return React.createElement(Div, {
      key: item.url.slice(1),
      my: "0.5rem"
    }, React.createElement(Link, {
      variant: "standalone",
      href: item.url,
      lineHeight: "dense",
      style: {
        display: "inline-block"
      }
    }, item.title));
  }))))))))), React.createElement(SiteFooter, null)));
};

export default PackageTemplate;
export var pageQuery = "1185730690";